import React, { Component } from "react";

const ResumeItem = ( {title, company, location, startDate, endData, highlight1, highlight2} ) => (
    <p>
        <b>{title},</b> {company}<br></br>
        <i>{location}</i><br></br>
        {startDate} - {endData}<br></br>
        <p>
            <ul>
                {highlight1 &&
                    <li>{highlight1}</li>
                }
                {highlight2 &&
                    <li>{highlight2}</li>
                }
            </ul>
        </p>
    </p>
)

export default ResumeItem