import * as React from 'react'
import ResumeItem from "../components/resumeItem"
import Layout from "../components/layout"

const Resume = () => (
    <Layout>
        <h1>Resume</h1>
        <p><a href="/resources/CV_Anuraag_2021.pdf" download>Download PDF version</a></p>
        <p><h2>Experiences</h2></p>
        <ResumeItem 
            title = "iOS Developer"
            company = "SumUp Limited"
            location = "Cologne and Berlin, Germany"
            startDate = "11.2018"
            endData = "present"
            highlight1 = "Using Swift and Objective-C from wireframe to implementation, developed features to provide users with support in the core product app used by over 250 million devices in over 20 countries."
            highlight2 = "Worked on the payment app loonshot project that brings consumers into the SumUp ecosystem all the way from concept stage to preliminary launch"
        />
        <ResumeItem 
            title = "Software Developer"
            company = "TobyRich GmbH"
            location = "Bremen, Germany"
            startDate = "02.2017"
            endData = "01.2018"
            highlight1 = "Refactored existing code to conform with MVVM greatly increasing maintainability, scalability and reusability"
            highlight2 = "Redesigned all view controllers using auto-layout for cross device and orientation             compatibility"
        />
        <ResumeItem 
            title = "Student Software Developer"
            company = "TobyRich GmbH"
            location = "Bremen, Germany"
            startDate = "11.2015"
            endData = "01.2017"
        />
        <ResumeItem 
            title = "Student IT Assistant"
            company = "Airbus Group"
            location = "Hamburg, Germany"
            startDate = "11.2014"
            endData = "09.2015"
        />
        <ResumeItem 
            title = "Research Assistant (part-time)"
            company = "Fraunhofer Institute for Wind Energy and Energy System Technology"
            location = "Bremerhaven, Germany"
            startDate = "09.2013"
            endData = "10.2014"
        />
        <p><h2>Education</h2></p>
        <ResumeItem
            title = "Master in Computers, Systems and Electronics"
            company = "Jacobs University Bremen"
            location = "Bremen, Germany"
            startDate = "09.2014"
            endData = "01.2017"
        />
        <ResumeItem
            title = "Bachelor in Electrical and Computer Engineering"
            company = "Jacobs University Bremen"
            location = "Bremen, Germany"
            startDate = "08.2013"
            endData = "09.2010"
        />
        <p><a href="/resources/CV_Anuraag_2021.pdf" download>Download PDF version</a></p>
    </Layout>
)

export default Resume